import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export enum ApplicationStatus {
  ApplicationStarted = 'APPLICATION_STARTED',
  BankStatementSubmitted = 'BANK_STATEMENT_SUBMITTED',
  ClosedWon = 'CLOSED_WON',
  EmploymentDetailsSubmitted = 'EMPLOYMENT_DETAILS_SUBMITTED',
  FirmAgreement = 'FIRM_AGREEMENT',
  FulfillConditions = 'FULFILL_CONDITIONS',
  FullApprovalAccepted = 'FULL_APPROVAL_ACCEPTED',
  FullApprovalDenied = 'FULL_APPROVAL_DENIED',
  FullApprovalInfoRequired = 'FULL_APPROVAL_INFO_REQUIRED',
  FullApprovalStarted = 'FULL_APPROVAL_STARTED',
  FullApprovalSubmitted = 'FULL_APPROVAL_SUBMITTED',
  IncomeVerificationDocumentsSubmitted = 'INCOME_VERIFICATION_DOCUMENTS_SUBMITTED',
  Invited = 'INVITED',
  NoLongerLooking = 'NO_LONGER_LOOKING',
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferDeclined = 'OFFER_DECLINED',
  OfferSubmitted = 'OFFER_SUBMITTED',
  PreparingOffer = 'PREPARING_OFFER',
  PreApprovalAccepted = 'PRE_APPROVAL_ACCEPTED',
  PreApprovalDenied = 'PRE_APPROVAL_DENIED',
  TouringHouses = 'TOURING_HOUSES'
}

export type ClientApplicationInfo = {
  __typename?: 'ClientApplicationInfo';
  applicationStatus: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  hasUnsubscribedFromEmails?: Maybe<Scalars['Boolean']>;
  homeBudget?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export enum EmailNotificationFrequency {
  BiWeekly = 'BI_WEEKLY',
  Disabled = 'DISABLED',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type Invitation = {
  __typename?: 'Invitation';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedAt?: Maybe<Scalars['AWSDateTime']>;
  applicationStatus?: Maybe<ApplicationStatus>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  targetCity?: Maybe<Scalars['String']>;
  wasPreviouslyInvited?: Maybe<Scalars['Boolean']>;
};

export type InvitationInput = {
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  targetCity?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  sendClientInvitation: Invitation;
  sendClientReminderEmail: Scalars['String'];
  updatePartnerInfo: PartnerInfo;
};


export type MutationSendClientInvitationArgs = {
  invitation: InvitationInput;
};


export type MutationSendClientReminderEmailArgs = {
  payload: ReminderEmailInput;
};


export type MutationUpdatePartnerInfoArgs = {
  partnerInfo: UpdatePartnerInfoInput;
};

export type PartnerInfo = {
  __typename?: 'PartnerInfo';
  createdAt: Scalars['AWSDateTime'];
  email: Scalars['AWSEmail'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  hasViewedOnboarding?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  notificationSchedule?: Maybe<EmailNotificationFrequency>;
  phoneNumber: Scalars['String'];
};

export type PartnerReferralUrl = {
  __typename?: 'PartnerReferralUrl';
  originalUrl: Scalars['String'];
  shortUrl: Scalars['String'];
};

export type PartnerReferrals = {
  __typename?: 'PartnerReferrals';
  applications?: Maybe<Array<Maybe<ClientApplicationInfo>>>;
  invitations?: Maybe<Array<Maybe<Invitation>>>;
};

export type Query = {
  __typename?: 'Query';
  partnerInfo: PartnerInfo;
  partnerReferralUrl: PartnerReferralUrl;
  partnerReferrals?: Maybe<PartnerReferrals>;
};

export type ReminderEmailInput = {
  clientEmail: Scalars['String'];
  emailBody: Scalars['String'];
  emailSubject: Scalars['String'];
  partnerEmail?: InputMaybe<Scalars['String']>;
  previewText: Scalars['String'];
};

export enum ReminderEmailType {
  ApplicationStarted = 'APPLICATION_STARTED',
  FullApprovalAccepted = 'FULL_APPROVAL_ACCEPTED',
  FullApprovalStarted = 'FULL_APPROVAL_STARTED',
  Invited = 'INVITED'
}

export type UpdatePartnerInfoInput = {
  hasViewedOnboarding?: InputMaybe<Scalars['Boolean']>;
  notificationSchedule?: InputMaybe<EmailNotificationFrequency>;
};

export type SendClientInvitationMutationVariables = Exact<{
  invitation: InvitationInput;
}>;


export type SendClientInvitationMutation = { __typename?: 'Mutation', sendClientInvitation: { __typename?: 'Invitation', givenName: string, familyName: string, email: string, wasPreviouslyInvited?: boolean | null } };

export type SendClientReminderEmailMutationVariables = Exact<{
  payload: ReminderEmailInput;
}>;


export type SendClientReminderEmailMutation = { __typename?: 'Mutation', sendClientReminderEmail: string };

export type UpdatePartnerInfoMutationVariables = Exact<{
  partnerInfo: UpdatePartnerInfoInput;
}>;


export type UpdatePartnerInfoMutation = { __typename?: 'Mutation', updatePartnerInfo: { __typename?: 'PartnerInfo', givenName: string, familyName: string, email: string, hasViewedOnboarding?: boolean | null, notificationSchedule?: EmailNotificationFrequency | null } };

export type PartnerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerInfoQuery = { __typename?: 'Query', partnerInfo: { __typename?: 'PartnerInfo', id: string, givenName: string, familyName: string, email: string, phoneNumber: string, createdAt: string, hasViewedOnboarding?: boolean | null, notificationSchedule?: EmailNotificationFrequency | null } };

export type PartnerReferralUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerReferralUrlQuery = { __typename?: 'Query', partnerReferralUrl: { __typename?: 'PartnerReferralUrl', shortUrl: string, originalUrl: string } };

export type PartnerReferralsQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerReferralsQuery = { __typename?: 'Query', partnerReferrals?: { __typename?: 'PartnerReferrals', invitations?: Array<{ __typename?: 'Invitation', createdAt?: string | null, email: string, givenName: string, familyName: string, note?: string | null, targetCity?: string | null, phoneNumber?: string | null } | null> | null, applications?: Array<{ __typename?: 'ClientApplicationInfo', applicationStatus: string, givenName: string, familyName: string, phoneNumber?: string | null, email: string, homeBudget?: string | null, createdAt: string, updatedAt?: string | null, hasUnsubscribedFromEmails?: boolean | null } | null> | null } | null };


export const SendClientInvitationDocument = gql`
    mutation sendClientInvitation($invitation: InvitationInput!) {
  sendClientInvitation(invitation: $invitation) {
    givenName
    familyName
    email
    wasPreviouslyInvited
  }
}
    `;
export type SendClientInvitationMutationFn = Apollo.MutationFunction<SendClientInvitationMutation, SendClientInvitationMutationVariables>;

/**
 * __useSendClientInvitationMutation__
 *
 * To run a mutation, you first call `useSendClientInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClientInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClientInvitationMutation, { data, loading, error }] = useSendClientInvitationMutation({
 *   variables: {
 *      invitation: // value for 'invitation'
 *   },
 * });
 */
export function useSendClientInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendClientInvitationMutation, SendClientInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendClientInvitationMutation, SendClientInvitationMutationVariables>(SendClientInvitationDocument, options);
      }
export type SendClientInvitationMutationHookResult = ReturnType<typeof useSendClientInvitationMutation>;
export type SendClientInvitationMutationResult = Apollo.MutationResult<SendClientInvitationMutation>;
export type SendClientInvitationMutationOptions = Apollo.BaseMutationOptions<SendClientInvitationMutation, SendClientInvitationMutationVariables>;
export const SendClientReminderEmailDocument = gql`
    mutation sendClientReminderEmail($payload: ReminderEmailInput!) {
  sendClientReminderEmail(payload: $payload)
}
    `;
export type SendClientReminderEmailMutationFn = Apollo.MutationFunction<SendClientReminderEmailMutation, SendClientReminderEmailMutationVariables>;

/**
 * __useSendClientReminderEmailMutation__
 *
 * To run a mutation, you first call `useSendClientReminderEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClientReminderEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClientReminderEmailMutation, { data, loading, error }] = useSendClientReminderEmailMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSendClientReminderEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendClientReminderEmailMutation, SendClientReminderEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendClientReminderEmailMutation, SendClientReminderEmailMutationVariables>(SendClientReminderEmailDocument, options);
      }
export type SendClientReminderEmailMutationHookResult = ReturnType<typeof useSendClientReminderEmailMutation>;
export type SendClientReminderEmailMutationResult = Apollo.MutationResult<SendClientReminderEmailMutation>;
export type SendClientReminderEmailMutationOptions = Apollo.BaseMutationOptions<SendClientReminderEmailMutation, SendClientReminderEmailMutationVariables>;
export const UpdatePartnerInfoDocument = gql`
    mutation updatePartnerInfo($partnerInfo: UpdatePartnerInfoInput!) {
  updatePartnerInfo(partnerInfo: $partnerInfo) {
    givenName
    familyName
    email
    hasViewedOnboarding
    notificationSchedule
  }
}
    `;
export type UpdatePartnerInfoMutationFn = Apollo.MutationFunction<UpdatePartnerInfoMutation, UpdatePartnerInfoMutationVariables>;

/**
 * __useUpdatePartnerInfoMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerInfoMutation, { data, loading, error }] = useUpdatePartnerInfoMutation({
 *   variables: {
 *      partnerInfo: // value for 'partnerInfo'
 *   },
 * });
 */
export function useUpdatePartnerInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerInfoMutation, UpdatePartnerInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerInfoMutation, UpdatePartnerInfoMutationVariables>(UpdatePartnerInfoDocument, options);
      }
export type UpdatePartnerInfoMutationHookResult = ReturnType<typeof useUpdatePartnerInfoMutation>;
export type UpdatePartnerInfoMutationResult = Apollo.MutationResult<UpdatePartnerInfoMutation>;
export type UpdatePartnerInfoMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerInfoMutation, UpdatePartnerInfoMutationVariables>;
export const PartnerInfoDocument = gql`
    query PartnerInfo {
  partnerInfo {
    id
    givenName
    familyName
    email
    phoneNumber
    createdAt
    hasViewedOnboarding
    notificationSchedule
  }
}
    `;

/**
 * __usePartnerInfoQuery__
 *
 * To run a query within a React component, call `usePartnerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerInfoQuery(baseOptions?: Apollo.QueryHookOptions<PartnerInfoQuery, PartnerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerInfoQuery, PartnerInfoQueryVariables>(PartnerInfoDocument, options);
      }
export function usePartnerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerInfoQuery, PartnerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerInfoQuery, PartnerInfoQueryVariables>(PartnerInfoDocument, options);
        }
export type PartnerInfoQueryHookResult = ReturnType<typeof usePartnerInfoQuery>;
export type PartnerInfoLazyQueryHookResult = ReturnType<typeof usePartnerInfoLazyQuery>;
export type PartnerInfoQueryResult = Apollo.QueryResult<PartnerInfoQuery, PartnerInfoQueryVariables>;
export const PartnerReferralUrlDocument = gql`
    query PartnerReferralUrl {
  partnerReferralUrl {
    shortUrl
    originalUrl
  }
}
    `;

/**
 * __usePartnerReferralUrlQuery__
 *
 * To run a query within a React component, call `usePartnerReferralUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerReferralUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerReferralUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerReferralUrlQuery(baseOptions?: Apollo.QueryHookOptions<PartnerReferralUrlQuery, PartnerReferralUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerReferralUrlQuery, PartnerReferralUrlQueryVariables>(PartnerReferralUrlDocument, options);
      }
export function usePartnerReferralUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerReferralUrlQuery, PartnerReferralUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerReferralUrlQuery, PartnerReferralUrlQueryVariables>(PartnerReferralUrlDocument, options);
        }
export type PartnerReferralUrlQueryHookResult = ReturnType<typeof usePartnerReferralUrlQuery>;
export type PartnerReferralUrlLazyQueryHookResult = ReturnType<typeof usePartnerReferralUrlLazyQuery>;
export type PartnerReferralUrlQueryResult = Apollo.QueryResult<PartnerReferralUrlQuery, PartnerReferralUrlQueryVariables>;
export const PartnerReferralsDocument = gql`
    query PartnerReferrals {
  partnerReferrals {
    invitations {
      createdAt
      email
      givenName
      familyName
      note
      targetCity
      phoneNumber
    }
    applications {
      applicationStatus
      givenName
      familyName
      phoneNumber
      email
      homeBudget
      createdAt
      updatedAt
      hasUnsubscribedFromEmails
    }
  }
}
    `;

/**
 * __usePartnerReferralsQuery__
 *
 * To run a query within a React component, call `usePartnerReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerReferralsQuery(baseOptions?: Apollo.QueryHookOptions<PartnerReferralsQuery, PartnerReferralsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerReferralsQuery, PartnerReferralsQueryVariables>(PartnerReferralsDocument, options);
      }
export function usePartnerReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerReferralsQuery, PartnerReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerReferralsQuery, PartnerReferralsQueryVariables>(PartnerReferralsDocument, options);
        }
export type PartnerReferralsQueryHookResult = ReturnType<typeof usePartnerReferralsQuery>;
export type PartnerReferralsLazyQueryHookResult = ReturnType<typeof usePartnerReferralsLazyQuery>;
export type PartnerReferralsQueryResult = Apollo.QueryResult<PartnerReferralsQuery, PartnerReferralsQueryVariables>;
export const namedOperations = {
  Query: {
    PartnerInfo: 'PartnerInfo',
    PartnerReferralUrl: 'PartnerReferralUrl',
    PartnerReferrals: 'PartnerReferrals'
  },
  Mutation: {
    sendClientInvitation: 'sendClientInvitation',
    sendClientReminderEmail: 'sendClientReminderEmail',
    updatePartnerInfo: 'updatePartnerInfo'
  }
}